import { authStore } from '$lib/stores/auth-store';
import { supabase } from './supabase';
import { get } from 'svelte/store';
import {
  findMessageByMessageId,
  findLiContactByName,
  getItemById,
} from './queries';

import {
  type Account,
  type Activity,
  type Deal,
  type Lovs,
  insertAccountsSchema,
  type ActivityWithRefs,
  type Issue,
  type Tariff,
  type Contract,
  type Lead,
  insertLeadSchema,
  insertShipmentSchema,
  LeadWithShipmentAndRate,
  type Ownership,
  insertOwnershipSchema,
  insertContractsSchema,
  type ContractWithRefs,
  OrderWithShipmentAndRate,
  insertOrderSchema,
  insertRateSchema,
  activityWithRefsInsertSchema,
  insertTimelogSchema,
  Favorite,
  type ExpenseWithRefs,
  insertExpensesSchema,
} from '$db/schema';
import { guardFinanceAccess } from '$lib/auth/guards';

type MessageType = {
  messageId: string;
  message: string;
  sender: string;
  time: string;
};

export type ChatUser = {
  userId: string;
  profileLink: string;
  name: string;
};

export async function saveLiMails(
  chatWithUser: ChatUser,
  messages: MessageType[],
) {
  if (messages && messages.length > 0) {
    console.debug('saveLiMails', messages);

    const found = await findLiContactByName(chatWithUser?.name);
    if (found.length === 0) {
      // TODO: import contact from linkedin
      return null;
    }

    if (found.length > 2) {
      // need to handle this
      // idea is to fetch profile url
      // then extract public identifier from content
      // and search via linkedin url
      throw new Error('More than one contact found');
    }

    const chatUser = found[0];

    const candidate =
      chatUser.role === 'Candidate' || chatUser.role === 'ContactAndCandidate'
        ? chatUser
        : null;
    const contact =
      chatUser.role === 'Contact' || chatUser.role === 'ContactAndCandidate'
        ? chatUser
        : null;

    const consultant = null; // = await findConsultantByName() .find(contact => contact.role === 'Employee');

    console.debug('consultant', consultant);
    console.debug('candidate', candidate);
    console.debug('contact', contact);

    const messagesToSave = await Promise.all(
      messages.map(async message => {
        const found = await findMessageByMessageId(message.messageId);
        if (found.length > 0) {
          return null;
        }

        return {
          type: 'LinkedIn',
          incommingMessage: chatWithUser.name === message.sender,
          messageId: message.messageId,
          name: message.sender,
          description: message.message,
          // consultant: consultant?.id,
          contacts: contact?.id
            ? [
                {
                  id: contact.id,
                  name: contact.name,
                },
              ]
            : [],
          candidates: candidate?.id
            ? [
                {
                  id: candidate.id,
                  name: candidate.name,
                },
              ]
            : [],
        };
      }),
    );

    console.debug('messagesToSave', messagesToSave);
    const { data, error } = await supabase(get(authStore)?.token)
      .from('activities')
      .insert(messagesToSave.filter(Boolean))
      .select();

    if (error) {
      console.debug('error', error);
      throw new Error(error.message);
    }
    return data;
  }
  return [];
}

export async function generateRefId(module: string) {
  const { data: refId, error } = await supabase(get(authStore)?.token).rpc(
    'get_seq_id',
    {
      p_table_name: module,
    },
  );
  if (error) {
    console.debug('error', error);
    // ignore
  } else {
    return refId;
  }
}

export async function createShipment(id, module, country) {
  const shipment = {
    operator: 'FedEx',
    product: 'DOX',
    weight: 0.25,
    weightPh: 0.25,
    weightUnit: 'kg',
    paymentSide: 'SENDER',
    direction: 'EXPORT',
    status: 'DRAFT',
    packageType: 'PL - FedEx Envelope',
    securityFee: true,
    fuelFee: true,
    paymentMethod: 'Перечислением',
    [module === 'leads' ? 'leadId' : 'orderId']: id,
    country,
  };
  await createItem('shipments', shipment);
}

export async function createItem(module, item) {
  if (!item.id && module !== 'invoices') {
    const refId = await generateRefId(module);
    if (refId) {
      item.refId = refId;
    }
  }

  if (module === 'leads') {
    item.date = new Date().toISOString();
  }
  const { data, error } = await supabase(get(authStore)?.token)
    .from(module)
    .insert([item])
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function updateItem(module, id, item) {
  return supabase(get(authStore)?.token).from(module).update(item).eq('id', id);
}

export async function deleteItem(module, id) {
  const oldItem = await getItemById(module, id);
  if (!oldItem['deleted']) {
    await updateItem(module, id, {
      deleted: true,
      deletedAt: new Date().toISOString(),
      deletedBy: get(authStore)?.user?.id,
    });
    return null;
  }

  const { data, error } = await supabase(get(authStore)?.token)
    .from(module)
    .delete()
    .eq('id', id);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function insertLov(lov: Lovs) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .insert(lov)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export async function updateLov(lov: Lovs): Promise<Lovs> {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .update({ values: lov.values })
    .eq('id', lov.id)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }

  return data[0] as Lovs;
}
export async function addContactToDeal({ dealId, contactId }) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('dealsToContacts')
    .insert({ dealId, contactId })
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export async function saveContact(contactToSave) {
  delete contactToSave.fts;
  delete contactToSave.accounts;
  delete contactToSave.deals;
  if (contactToSave.customer && contactToSave.customer.id) {
    contactToSave.customer = contactToSave.customer.id;
  }
  contactToSave.name =
    (contactToSave.firstname ? contactToSave.firstname + ' ' : '') +
    (contactToSave.lastname ?? '');

  const refIds = [
    ...(contactToSave.refIds || []),
    contactToSave.email ?? '',
    contactToSave.communicationPrivate?.email ?? '',
    contactToSave.communicationPrivate?.secondaryEmail ?? '',
    contactToSave.communicationWork?.email ?? '',
    contactToSave.communicationWork?.secondaryEmail ?? '',
  ].map(id => id?.toLowerCase());

  contactToSave.refIds = Array.from(new Set(refIds.filter(Boolean)));

  if (!contactToSave.refId) {
    const refId = await generateRefId('contacts');
    contactToSave.refId = refId;
  }

  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .upsert(contactToSave)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export async function activitiesToAccount(activitiesToAccount) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('activitiesToAccounts')
    .upsert(activitiesToAccount)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export const updateContactRole = async (role, id) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .update({ role })
    .eq('id', id);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const updateAccountContact = async (contactId, account) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .update({ customer: account.id })
    .eq('id', contactId);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const updateAccount = async id => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('accounts')
    .update({ modifiedAt: new Date().toISOString() })
    .eq('id', id);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const updateStatus = async (status, id) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('activities')
    .update({ status })
    .eq('id', id);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const saveTimelog = async timelog => {
  const { duration, ...timelogToSave } = insertTimelogSchema.parse(timelog);
  const { data, error } = await supabase(get(authStore)?.token)
    .from('timelogs')
    .upsert(timelogToSave)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }

  return data[0];
};

export const saveFilter = async filter => {
  const { filters, ...filterToSave } = filter;
  const { data, error } = await supabase(get(authStore)?.token)
    .from('filters')
    .upsert(filterToSave)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }

  return data[0];
};

export const saveOrder = async (
  order: OrderWithShipmentAndRate,
): Promise<OrderWithShipmentAndRate> => {
  const orderToSave = insertOrderSchema.parse(order);

  if (!orderToSave.refId) {
    const refId = await generateRefId('orders');
    orderToSave.refId = refId;
  }
  orderToSave.customer = order.account?.id;
  orderToSave.contact = order.contactObj?.id;
  orderToSave.assignedTo = order.user?.id;

  orderToSave.totalAmountNational = order.shipment?.totalNational;
  orderToSave.totalAmountEUR = order.shipment?.totalEUR;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('orders')
    .upsert(orderToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    const result = data[0];
    result.shipment = await saveShipmentAndRate(
      order.shipment,
      order.shipment?.leadId,
      result.id,
    );
    return result;
  }
};

export async function updateAccountLogo(accountId: string, logo: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('accounts')
    .update({ logo })
    .eq('id', accountId);

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }
}

async function saveShipmentAndRate(
  shipment: LeadWithShipmentAndRate,
  leadId: string,
  orderId: string,
) {
  if (!shipment) return null;

  const shipmentToSave = insertShipmentSchema.parse(shipment);
  shipmentToSave.leadId = leadId;
  shipmentToSave.orderId = orderId;
  const { data: shipmentData, error: shipmentError } = await supabase(
    get(authStore)?.token,
  )
    .from('shipments')
    .upsert(shipmentToSave)
    .select();

  if (shipmentError) {
    throw Error(shipmentError.message);
  }

  // Set the shipment ID for the rate
  shipment.rate.shipmentId = shipmentData[0].id;
  const rateToSave = insertRateSchema.parse(shipment.rate);

  // First try to get existing rate
  const { data: existingRate } = await supabase(get(authStore)?.token)
    .from('rates')
    .select()
    .eq('shipmentId', shipmentData[0].id)
    .single();

  // If rate exists, include its ID for update
  if (existingRate) {
    rateToSave.id = existingRate.id;
  }

  const { data: rateData, error: rateError } = await supabase(
    get(authStore)?.token,
  )
    .from('rates')
    .upsert(rateToSave)
    .select();

  if (rateError) {
    throw Error(rateError.message);
  }

  return {
    ...shipmentData[0],
    rate: rateData[0],
  };
}

// Then modify the saveLead function
export const saveLead = async (
  lead: LeadWithShipmentAndRate,
): Promise<Lead> => {
  const leadToSave = insertLeadSchema.parse(lead);
  leadToSave.customer = lead.account?.id;
  leadToSave.contact = lead.contactObj?.id;
  leadToSave.assignedTo = lead.user?.id;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('leads')
    .upsert(leadToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    const result = data[0];
    result.shipment = await saveShipmentAndRate(
      lead.shipment,
      result.id,
      lead.shipment?.orderId,
    );
    return result;
  }
};

export const saveActivity = async (
  activity: ActivityWithRefs,
): Promise<Activity> => {
  console.log('save activity', activity);
  let activityToSave = activityWithRefsInsertSchema.parse(activity);

  delete activityToSave.allContacts;

  delete activityToSave.consultant;
  if (activity?.consultantObj?.id) {
    activityToSave.consultant = activity.consultantObj.id;
  }

  if (activityToSave.consultantObj) {
    delete activityToSave.consultantObj.photo;
  }

  if (activity.type === 'Assignment') {
    activityToSave.account = activity.accountObj?.id;
    activityToSave.deal = activity.dealObj?.id;
    activityToSave.candidate = activity.candidateObj?.id;

    if (activityToSave.accountObj) {
      delete activityToSave.accountObj.logo;
    }
    if (activityToSave.candidateObj) {
      delete activityToSave.candidateObj.photo;
    }

    if (!activityToSave.accounts) {
      activityToSave.accounts = [];
    }

    if (!activityToSave.deals) {
      activityToSave.deals = [];
    }

    if (!activityToSave.candidates) {
      activityToSave.candidates = [];
    }

    activityToSave.accounts.push(activityToSave.accountObj);
    activityToSave.deals.push(activityToSave.dealObj);
    activityToSave.candidates.push(activityToSave.candidateObj);
  }

  activityToSave.refs = [
    ...(activity.accounts?.map(account => account?.id) || []),
    ...(activity.deals?.map(deal => deal?.id) || []),
    ...(activity.contacts?.map(contact => contact?.id) || []),
    ...(activity.candidates?.map(contact => contact?.id) || []),
    ...(activity.issues?.map(issue => issue?.id) || []),
  ];

  delete activityToSave.consultantObj;
  delete activityToSave.accountObj;
  delete activityToSave.dealObj;
  delete activityToSave.candidateObj;
  delete activityToSave.fts;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('activities')
    .upsert(activityToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export const deleteOwnership = async (id: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('ownerships')
    .delete()
    .eq('id', id);
  return data;
};

export const saveOwnership = async (
  ownership: Ownership,
  accountId: string,
): Promise<Ownership> => {
  if (!guardFinanceAccess()) {
    throw Error('Access denied');
  }
  ownership.accountId = accountId;
  ownership.salesId = ownership.sales?.id;
  const ownershipToSave = insertOwnershipSchema.parse(ownership);

  const { data, error } = await supabase(get(authStore)?.token)
    .from('ownerships')
    .upsert(ownershipToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export const saveContract = async (
  contract: ContractWithRefs,
): Promise<Contract> => {
  contract.contactId = contract.contact?.id;
  contract.accountId = contract.account?.id;
  contract.salesId = contract.sales?.id;
  contract.baseTariffId = contract.baseTariffObj?.id;
  contract.customerTariffId = contract.customerTariffObj?.id;

  const contractToSave = insertContractsSchema.parse(contract);
  delete contractToSave.fts;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('contracts')
    .upsert(contractToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export const saveTariff = async (tariff: Tariff): Promise<Tariff> => {
  const tariffToSave = { ...tariff };

  const { data, error } = await supabase(get(authStore)?.token)
    .from('tariffs')
    .upsert(tariffToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export const saveIssue = async (issue: Issue): Promise<Issue> => {
  const issueToSave = { ...issue };

  issueToSave.accountId = issueToSave.account?.id;
  issueToSave.reporterId = issueToSave.reporter?.id;
  issueToSave.responsibleId = issueToSave.responsible?.id;
  issueToSave.orderId = issueToSave.order?.id;

  delete issueToSave.fts;
  delete issueToSave.reporter;
  delete issueToSave.responsible;
  delete issueToSave.invoice;
  delete issueToSave.order;
  delete issueToSave.account;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('issues')
    .upsert(issueToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export const saveAccount = async (account: Account): Promise<Account> => {
  const accountToSave = insertAccountsSchema.parse(account);
  delete accountToSave.fts;

  if (!account.refId) {
    const refId = await generateRefId('accounts');
    accountToSave.refId = refId;
  }
  accountToSave.salesId = account.sales?.id || null;
  accountToSave.baseTariff = account.baseTariffObj?.id || null;
  accountToSave.customerTariff = account.customerTariffObj?.id || null;
  accountToSave.supplierTariff = account.supplierTariffObj?.id || null;

  let response = await supabase(get(authStore)?.token)
    .from('accounts')
    .upsert(accountToSave)
    .select();
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return insertAccountsSchema.parse(data[0]);
  }
};

export const saveDeal = async (deal: Deal): Promise<Deal> => {
  const dealToSave = { ...deal };
  if (deal.customerObj && deal.customerObj.id) {
    dealToSave.customer = deal.customerObj.id;
    delete dealToSave.customerObj;
  }
  let response = await supabase(get(authStore)?.token)
    .from('deals')
    .upsert(dealToSave)
    .select();

  console.debug('response', response);
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export async function saveFavorite(favorite: Favorite) {
  const { error } = await supabase(get(authStore)?.token)
    .from('favorites')
    .insert(favorite);
  if (error) throw error;
}

export async function deleteFavorite(field: string, id: string) {
  const { error } = await supabase(get(authStore)?.token)
    .from('favorites')
    .delete()
    .eq(field, id);
  if (error) throw error;
}

export async function savePayment(payment: any) {
  const { error } = await supabase(get(authStore)?.token)
    .from('payments')
    .insert([payment]);

  if (error) throw error;
}

export async function updatePayment(payment: any) {
  const { error } = await supabase(get(authStore)?.token)
    .from('payments')
    .update({
      amount: payment.amount,
      // Add other fields that might need updating
    })
    .eq('id', payment.id);

  if (error) throw error;
}

export const saveExpense = async (
  expense: ExpenseWithRefs,
): Promise<ExpenseWithRefs> => {
  expense.accountId = expense.account?.id;

  const expenseToSave = insertExpensesSchema.parse(expense);
  delete expenseToSave.fts;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('expenses')
    .upsert(expenseToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export async function updateSourceField(
  module: string,
  objectId: string,
  fieldName: string,
  value: any,
) {
  const response = await fetch(`/api/${module}/${objectId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      [fieldName]: value,
    }),
  });

  if (!response.ok) {
    throw new Error(`Failed to update ${module} source field`);
  }

  return response.json();
}

export async function updatePipelinePhase(id: string, phase: any) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelinePhases')
    .update(phase)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
}
