<script lang="ts">
  import { cn } from '$lib/utils/hanta-utils';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { restoreItem, deleteItemPermanently } from '$lib/api/trash-api';
  import { formatDistanceToNow } from 'date-fns';
  import Icon from '@iconify/svelte';
  import { saveFavorite, deleteFavorite } from '$lib/api/mutations';
  import { getFavorites } from '$lib/api/queries';
  import { get } from 'svelte/store';
  import CrmDeleteObject from '$lib/ui/crm-delete-object.svelte';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import {
    createMutation,
    createQuery,
    useQueryClient,
  } from '@tanstack/svelte-query';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Input } from '$lib/components/ui/input';
  import AddToPipeline from '$lib/components/pipelines/crm/add-to-pipeline.svelte';

  export let module;
  export let id;
  export let item;

  // Array of modules that can be favorited
  const FAVORITABLE_MODULES = ['accounts', 'deals', 'contacts', 'invoices'];

  // Check if current module can be favorited
  $: canBeFavorited = FAVORITABLE_MODULES.includes(module);

  $: field = `${module.slice(0, -1)}Id`;

  let showDeleteDialog = false;
  const queryClient = useQueryClient();

  // Query to fetch favorites using getFavorites
  const favoritesQuery = createQuery({
    queryKey: ['favorites'],
    queryFn: getFavorites,
    enabled: canBeFavorited, // Only run query if module can be favorited
  });

  // Check if current item is favorited
  $: isFavorited =
    canBeFavorited && $favoritesQuery.data?.some(fav => fav[field] === id);

  // Get favorite ID if exists
  $: favoriteId =
    canBeFavorited && $favoritesQuery.data?.find(fav => fav[field] === id)?.id;

  const saveFavoriteMutation = createMutation({
    mutationFn: async () => {
      await currentUserStore.loadUser();
      const currentUser = get(currentUserStore);
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const favoriteItem = {
        userId: currentUser.id,
        [field]: id,
      };
      return saveFavorite(favoriteItem);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['favorites'] });
    },
  });

  const deleteFavoriteMutation = createMutation({
    mutationFn: async () => {
      return deleteFavorite(field, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['favorites'] });
    },
  });

  const onDelete = () => {
    showDeleteDialog = true;
  };

  const toggleFavorite = () => {
    if (isFavorited) {
      $deleteFavoriteMutation.mutate();
    } else {
      $saveFavoriteMutation.mutate();
    }
  };

  const onDeleteFn = () => {
    refreshQuery();
  };

  const refreshQuery = () => {
    queryClient.invalidateQueries({ queryKey: [module, id] });
    queryClient.invalidateQueries({ queryKey: [module] });
    queryClient.invalidateQueries({ queryKey: ['trash'] });
  };

  async function onRestore() {
    await restoreItem(module, id);
    refreshQuery();
  }

  async function onDeleteItemPermanently() {
    await deleteItemPermanently(module, id);
    refreshQuery();
  }

  let showMergeDialog = false;
  let searchQuery = '';
  let selectedItemToMerge = null;
  let mergeNote = ''; // For the note field at the bottom

  // Function to get mergeable items (filtered list excluding current item)
  $: mergeableItems = [];

  async function handleMerge() {
    try {
      if (!selectedItemToMerge) return;
      // await mergeItems(id, selectedItemToMerge.id, mergeNote);
      showMergeDialog = false;
      selectedItemToMerge = null;
      mergeNote = '';
      refreshQuery();
    } catch (error) {
      console.error('Error merging items:', error);
    }
  }
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Button
          builders={[builder]}
          variant="link"
          size="sm"
          class="text-muted-foreground"
        >
          <span>Actions</span>
          <Icon
            icon="mdi:chevron-down"
            class="ml-4 w-4 h-4 text-muted-foreground"
          />
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <p>More actions</p>
      </Tooltip.Content>
    </Tooltip.Root>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content class="w-56">
    <DropdownMenu.Item
      disabled={module === 'invoices' && item?.status !== 'Draft'}
      on:click={onDelete}
    >
      <Icon icon="ph:trash-duotone" class="mr-2 w-4 h-4" />
      Delete
    </DropdownMenu.Item>
    <!--DropdownMenu.Item on:click={() => (showMergeDialog = true)}>
      <Icon icon="mdi:call-merge" class="mr-2 w-4 h-4" />
      Merge
    </DropdownMenu.Item-->
    <!-- 
      View history
      Move to trash
      Duplicate
      Notify me
      Import 
      Export
      Copy link
      View all comments
    -->
  </DropdownMenu.Content>
</DropdownMenu.Root>

<div class="flex gap-2 items-center">
  {#if canBeFavorited}
    <Tooltip.Root>
      <Tooltip.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          on:click={toggleFavorite}
          variant="ghost"
          size="icon"
          disabled={$favoritesQuery.isLoading ||
            $saveFavoriteMutation.isPending ||
            $deleteFavoriteMutation.isPending}
          class="hover:bg-muted"
        >
          {#if $favoritesQuery.isLoading || $saveFavoriteMutation.isPending || $deleteFavoriteMutation.isPending}
            <Icon icon="ph:spinner-gap" class="w-4 h-4 animate-spin" />
          {:else if isFavorited}
            <Icon icon="ph:star-fill" class="w-4 h-4 text-yellow-500" />
          {:else}
            <Icon icon="ph:star-duotone" class="w-4 h-4" />
          {/if}
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <p>
          {isFavorited ? 'Remove from favorites' : 'Add to favorites'}
        </p>
      </Tooltip.Content>
    </Tooltip.Root>
  {/if}

  {#if item?.deleted}
    <div
      class="flex absolute top-0 left-0 justify-between items-center px-4 py-3 pr-12 w-full text-xl text-white bg-red-500"
    >
      <span
        >This {module?.slice(0, -1)} has been moved to Trash
        {formatDistanceToNow(new Date(item?.deletedAt))} ago</span
      >
      <div class="flex gap-2">
        <Button variant="outline" class="text-primary" on:click={onRestore}>
          <Icon icon="mdi:restore" class="mr-2 size-4" />
          Restore {module?.slice(0, -1)}
        </Button>
        <Button
          variant="outline"
          class="text-primary"
          on:click={onDeleteItemPermanently}
        >
          <Icon icon="mdi:trash-can-outline" class="mr-2 size-4" />
          Delete from Trash
        </Button>
      </div>
    </div>
  {/if}
</div>
<CrmDeleteObject bind:open={showDeleteDialog} {module} {id} {onDeleteFn} />

<!-- Merge Dialog -->
<Dialog.Root bind:open={showMergeDialog}>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <div class="flex gap-2 items-center">
        <Icon icon="mdi:call-merge" class="w-5 h-5 text-muted-foreground" />
        <Dialog.Title>Merge {module?.slice(0, -1)}</Dialog.Title>
      </div>
    </Dialog.Header>

    <div class="py-4 space-y-4">
      <!-- Select Section -->
      <div>
        <label for="search" class="block mb-2 text-sm font-medium"
          >Select {module?.slice(0, -1)}</label
        >
        <div class="relative">
          <Input
            type="text"
            id="search"
            placeholder={`Search by ID or name...`}
            bind:value={searchQuery}
            class="pl-8"
          />
          <Icon
            icon="mdi:magnify"
            class="absolute left-2 top-1/2 w-4 h-4 -translate-y-1/2 text-muted-foreground"
          />
        </div>

        <!-- Search Results -->
        <div class="mt-2 rounded-md border">
          <div class="max-h-[200px] overflow-y-auto">
            {#each mergeableItems.filter(i => i.name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) || i.id
                  .toString()
                  .includes(searchQuery)) as mergeItem}
              <button
                class={cn(
                  'w-full px-3 py-2 flex items-center gap-2',
                  'hover:bg-muted/50 transition-colors border-b last:border-b-0',
                  selectedItemToMerge?.id === mergeItem.id && 'bg-muted',
                )}
                on:click={() => (selectedItemToMerge = mergeItem)}
              >
                <Icon icon="mdi:ticket" class="w-4 h-4 text-muted-foreground" />
                <div class="flex flex-col items-start text-sm">
                  <span>{mergeItem.id}</span>
                  <span class="text-muted-foreground">{mergeItem.name}</span>
                </div>
              </button>
            {/each}
          </div>
        </div>
      </div>

      <!-- Note Section -->
      <div>
        <label for="note" class="block mb-2 text-xl font-medium">Note</label>
        <textarea
          id="note"
          bind:value={mergeNote}
          class="w-full min-h-[100px] p-3 rounded-md border resize-none focus:outline-none focus:ring-2 focus:ring-ring"
          placeholder="Add a note about this merge..."
        />
      </div>
    </div>

    <Dialog.Footer>
      <Button variant="outline" on:click={() => (showMergeDialog = false)}>
        Cancel
      </Button>
      <Button
        variant="default"
        disabled={!selectedItemToMerge}
        on:click={handleMerge}
      >
        Merge {module?.slice(0, -1)}
      </Button>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>

<AddToPipeline {module} {item} />
